.login-container {
  width: 50%;
  max-width: 400px; }
  .login-container .login-label::after {
    display: none; }
  .login-container .login-spacer {
    visibility: hidden;
    color: transparent; }
  .login-container.logo {
    text-align: center; }
    .login-container.logo img {
      max-width: 100%; }
    .login-container.logo > md-icon {
      width: 100px;
      height: 100px; }
  .login-container.login-inputs > md-input-container {
    padding-left: 22px;
    margin: 0; }
    .login-container.login-inputs > md-input-container > label {
      left: 20px; }
  .login-container > .md-raised.md-button {
    border-radius: 20px;
    width: 100%;
    background-color: #21CE99; }

.field-login {
  text-align: center;
  font-size: .9rem; }
  .field-login a {
    color: rgba(0, 0, 0, 0.38);
    text-decoration: none; }

.login-footer {
  text-align: center;
  color: rgba(0, 0, 0, 0.38); }

.md-toast-content {
  opacity: 0.8; }
  .toast-warning .md-toast-content {
    background-color: #811B00 !important; }
